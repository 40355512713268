import { useState } from "react";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { useEffect } from "react";
import Dropzone from "../global/dropzone";

const AddDocument = (props) => {
    const [documentDetails,                 setDocumentDetails]        = useState({});
    const [error,                           setError]                  = useState('');

    useEffect(() => {
        $('#add-documents-modal').modal({
            backdrop: 'static',
        });
        $('#add-documents-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);  
            props.setEditDocument(null) 
            // setDocumentDetails({});
        });
        
    }, [])

    useEffect(() => {
        if(props.editDocument){
            setDocumentDetails(props.editDocument)
        }
    },[props.editDocument])

    useEffect(() => {
    },[documentDetails])

    const documentHandler = async (d) => {
        setDocumentDetails(a => ({...a, Document: d}))
    };  

    const removeDocumentHandler = async(e) => {
		e.preventDefault();	
        setDocumentDetails(a => ({...a, Document: ''}))
	}
 
    const updateDocumentDetails = (field, value) => { 
        setDocumentDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const addDocumentHandler = async(e) => {
        e.preventDefault()
 
        if (!documentDetails.DocumentType || documentDetails.DocumentType.trim() === '') {
            setError('Document Name is required.');
            return;
        }
        if(!documentDetails.Document){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Error!',
                text                  : `Please Select Document`,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return
        }
        
        try {
            props.setDocumentDetails(documentDetails)
            $('#add-documents-modal').modal('hide');
            
            swal.fire({
                icon: 'success',
                titleText: `Document ${documentDetails.ID ? "updated" : "added"} successfully!`,
                showConfirmButton: false,
                timer: 1500,
            });
   
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return
        }

        setDocumentDetails({});
        props.setEditDocument(null)
    };
    

    return(
        <>
            <div className="modal fade" id="add-documents-modal" tabIndex="-1" role="dialog" aria-hidden="true">       
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Documents</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form id="add-document-form">
                                <div className="panel panel-paper">
                                    <div className="form-group padding-lr50 padding-t50">
                                        {/* <label htmlFor="inputFirstName" className="control-label">Focused input</label> */}
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="ddlDocumentName"
                                            name="ddlDocumentName"
                                            placeholder="Document Name"
                                            value={documentDetails.DocumentType || ''} 
                                            onChange={(event) => {updateDocumentDetails('DocumentType', event.target.value), setError('')}}
                                        />
                                        {error && <div className="invalid-feedback" >{error}</div>} {/* Display error message */}
                                    </div>
                                    <div className="form-group row padding-lr50 padding-tb20">
                                        <div className="col-12">
                                            {documentDetails?.Document || documentDetails?.File || documentDetails?.name ? (
                                                <div className="docImg margin-b0 padding-tb10 padding-lr20" style={{ backgroundColor: '#f7f8fa', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <img src={helper.getFileLogo(documentDetails?.Document?.name || documentDetails?.name)} alt="user-profile" height="30px" width="30px" className="margin-r10" />
                                                    </div>
                                                    <div style={{paddingRight: '360px'}}>
                                                        File
                                                    </div>
                                                    <button 
                                                        type="button"
                                                        style={{ width: "20px", height: "20px" }}                    
                                                        onClick={removeDocumentHandler}
                                                        className="btn btn-xs btn-danger btn-user-delete">
                                                        <i className="fa fa-trash-o icon-only"></i>              
                                                    </button>
                                                </div>
                                            ):(
                                                <Dropzone fileHandler={documentHandler} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-default margin-0 margin-r5"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                id="btnSave"
                                onClick={addDocumentHandler}
                                type="button"
                                className={`btn btn-primary margin-0 `}
                            >                  
                                {props.editDocument ? 'Update' : 'Add'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddDocument;