import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { verifyEmail } from '../api/loginAPI';


const VerifyEmail = (props) => {

    const [verified,         setVerified]         = useState(false);
    const [verifiedEmail,    setVerifiedEmail]    = useState('');

    const queryParameters = new URLSearchParams(window.location.search);
    const userId = queryParameters.get("id");
    const email = queryParameters.get("email");

    useEffect(() => {

        if (userId && email) {
            verifyHandler(userId, email);
        } else {
            Swal.close();
            Swal.fire({
                icon              : "error",
                titleText         : "Error: Invalid Email",
                showConfirmButton   : false,
                allowOutsideClick   : false
            })
        }
    }, []);

    const verifyHandler = async (userId, email) => {
        try {
            Swal.fire({
                titleText           : 'Loading',
                text                : 'Please Wait...',
                showConfirmButton   : false,
                allowOutsideClick   : false,
                onOpen: () => {
                    Swal.showLoading();
                }
            });
            await verifyEmail(userId, email);
            setVerified(true);
            setVerifiedEmail(email);
            Swal.close();
        } catch (err) {
            Swal.close();
            Swal.fire({
                icon              : "error",
                titleText         : err.message,
                showConfirmButton : false,
                allowOutsideClick : false
            })
            return;  
        }
    }

    return (
        <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="" style={{marginTop: '15%'}}>
                    {verified && (
                        <h3 className="text-center">
                            <span>You have successfully verified {verifiedEmail }</span>
                            <p>
                                <i className="fa fa-check-circle-o green bigger-300" style={{marginTop: '20px', fontSize: '200%', color: '#69aa46'}} aria-hidden="true"></i>
                            </p>
                        </h3>
                    )}
                </div>
            </div>
            
        </div>
    </div>
    )
}

export default VerifyEmail;