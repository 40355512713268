import React, { useEffect, useState } from 'react';
import Loader from '../global/loader';
import swal from 'sweetalert2';
import {getTeeTimeInit, getMyTeeTimeBooking, getTeeTimeBookUsers } from "../api/teeTimeAPI";
import BookingModal from './bookingModal';
import { getAuthState } from '../../assets/scripts/login-util';

const MyBooking = (props) => {

    const [loading,          setLoading]       = useState(false);    
    const [pageData,         setPageData]      = useState();
    const [bookingList,      setBookingList]   = useState([]);
    const [BookingUsers,     setBookingUsers]  = useState([]);

    const [teeBook,          setTeeBook] = useState();

    const [editValidation, setEditValidation] = useState();

    useEffect(() => {
        fetchMyBooking();
    }, [])
       
    const fetchMyBooking = async()=> {
        try {
            setLoading(true);
            const { TeeTimeValidation, ServerDate } = await getTeeTimeInit();               
            const response = await getMyTeeTimeBooking(); 
                                    
            setPageData({TeeTimeValidation, ServerDate}); 
            setBookingList(response.TeeTimeBook);            

            setLoading(false);
        } catch (err) {
            setLoading(false);
            swal.fire({ icon: "error", titleText: "Error!", text: err.message })
            return;
        }
    }

    const fetchBookingUsers = async(id)=> {
        try {
            const response = await getTeeTimeBookUsers(id); 
            setBookingUsers(response.User);          
        } catch (err) {           
            swal.fire({ icon: "error", titleText: "Error!", text: err.message })
            return;
        }
    }

    const onSelectBook =(book) => { 
        const user = getAuthState('user');
        //Set Edit & Cancel Flags -->
        const {TeeTimeValidation, ServerDate } = pageData;

        const blockDate  = moment(book.BookDate).add(TeeTimeValidation.defaultBookingHours, 'h').add(TeeTimeValidation.defaultBookingMinutes, 'm');
        const editDate   = moment(ServerDate).add(TeeTimeValidation.editHours, 'h').add(TeeTimeValidation.editMinutes, 'm');
        const cancelDate = moment(ServerDate).add(TeeTimeValidation.cancelHours, 'h').add(TeeTimeValidation.cancelMinutes, 'm');
        
        const isOwner = (user?.id == book.UserID);

        setEditValidation({
            IsEdit  : (moment(blockDate).isAfter(editDate)),
            IsCancel: (isOwner && (moment(blockDate).isAfter(cancelDate)))    
        });
        
        setTeeBook(book);
        fetchBookingUsers(book.ID);
    }

    return(<>
        {/* Listing */}

        <div className="row margin-t20">
            <div className="col-xs-12">
                {loading? <Loader/> : (<>
                    <div className="panel panel-default panel-club">
                        <table id="tblMyTeeTime" className="table table-striped">                      
                            <tbody>
                                {( bookingList.length > 0) ? (
                                    bookingList.map((b,i)=> (
                                        <tr key={b.ID}  onClick={()=> onSelectBook(b)}>
                                            <td className="td-block">
                                                <span ><b> {moment(b.BookDate).format('DD-MMM-YYYY')}, {b.Type == 'Shotgun' ? b.StartTime : b.Slot} </b> </span> <br/>
                                                <span >{b.Type == 'Shotgun' ? 'Shotgun Start,' + (' Hole ' + b.HoleNumber) : 'Staggered Start, ' + (b.TeeBox == 10 ? '10th Tee' : '1st Tee')} </span>
                                            </td>                                    
                                            
                                            {!!pageData && (
                                                moment(moment(b.BookDate).add(pageData.TeeTimeValidation.defaultBookingHours, 'h').add(pageData.TeeTimeValidation.defaultBookingMinutes, 'm')).isAfter(
                                                    moment(pageData.ServerDate).add(pageData.TeeTimeValidation.editHours, 'h').add(pageData.TeeTimeValidation.editMinutes, 'm')
                                                )) ? ( <td className="text-right"><i className="fa fa-pencil fa-lg margin-lr10" aria-hidden="true"></i></td>) : <td></td>                                                
                                            }
                                        </tr>
                                    ))
                                ):(
                                    <tr>
                                        <td>
                                            <div className="row wow fadeInUp animated">
                                                <div className="col-xs-12">
                                                    <h4 className="text-center text-uppercase text-muted margin-tb50">No Bookings Yet</h4>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )                                
                               }
                            </tbody>
                        </table>
                    </div>
                </>) }
            </div>
        </div>


        {(!!teeBook) && (
                <BookingModal
                    show                = {!!teeBook }
                    action              = {'e'}
                    onDismissModal      = {() => setTeeBook(null) }
                    teeTimeBookData     = {teeBook}
                    SelectedDate        = {teeBook.BookDate}
                    bookingMembers      = {BookingUsers}
                    editValidation      = {editValidation}
                    refetchTeeTime      = {() => fetchMyBooking()}
                />
            )}

    </>)
}

export default MyBooking;