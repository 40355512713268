import { useEffect, useState } from "react";
import Swal from 'sweetalert2';

const UserOnboarded = (props) => {

    const [memberName,            setMemberName]            = useState('');
    const [membershipType,        setMembershipType]      = useState('');

    const queryParameters = new URLSearchParams(window.location.search);
    const userName = queryParameters.get("userName");
    const type = queryParameters.get("type");
 

    useEffect(() => {
        if (userName && type) {
            setMemberName(userName);
            setMembershipType(type);
        } else {
            // Swal.close();
            // Swal.fire({
            //     icon              : "error",
            //     titleText         : "Error: Invalid Email",
            //     showConfirmButton   : false,
            //     allowOutsideClick   : false
            // })
        }
    }, []);

    return(
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="" style={{marginTop: '15%'}}>
                            {membershipType != '' && memberName  != ''  &&(
                                <h3 className="text-center">
                                    <p>
                                        <i className="fa fa-check-circle-o green bigger-300" style={{marginTop: '20px', fontSize: '200%', color: '#69aa46'}} aria-hidden="true"></i>
                                    </p>
                                    <h4>{membershipType}</h4>
                                    <h4>{memberName}</h4>
                                    <span>Onboarded successfully.</span>
                                </h3>
                            )} 
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
};

export default UserOnboarded;