import React from 'react';

const TeeCard = (props)=> {
    const { book, onBookSelect, isBookingAllowed } = props;

    return(<>
        <div  className="col-md-6 col-sm-6 col-lg-3 padding-0" >
            <div className={`slot-card ${ (!!book.Status || (isBookingAllowed) ) && 'disableClick'}`} onClick={() => onBookSelect(book)} >
                <div className={`${ (!book.Status) ? 'slot-card-header':(book.Status == 'Pending')? 'slot-card-header-pending' :'slot-card-header-blur' }`}> 
                    <div className="row margin-0">
                        <div className="col-xs-12 padding-0">
                            <b> {book.Slot} </b>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}


export default TeeCard;