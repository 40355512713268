const Loader = (props) => {
    return(
        <>
            <div className=" panel" id="loadTeeTime">
                <div className="club-loader text-center">
                    <i className="fa fa-circle-o-notch fa-spin fa-fw"></i>
                </div>
            </div>
        </>
    )
}

export default Loader;