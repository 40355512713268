import React, { useState, useEffect } from 'react';
import swal from 'sweetalert2';
import Helmet from 'react-helmet';
import bannerLogo from '../../assets/images/GBGC_Login_logo.png';
import { rejectScoreCard, validateScoreCard } from '../api/gameAPI';

const GameVerification = (props) => {

    const [message,               setMessage]            = useState('');
    const [isReject,              setIsReject]           = useState(false);
    const [iconType,              setIconType]           = useState('')
    const [reason,                setReason]             = useState('');

    const queryParameters = new URLSearchParams(window.location.search);
    const userID            = queryParameters.get("id");
    const gameID            = queryParameters.get("gameId");
    const action            = queryParameters.get("action");
    const verifiedUserID    = queryParameters.get("userID");

    useEffect(() => {
        verifyGameScorecardData(userID, gameID, verifiedUserID, action)
    }, [userID, gameID, verifiedUserID,action]);

    useEffect(() => {
        validationAttachments();
    });

    const verifyGameScorecardData = async (userID, gameID, verifiedUserID, action) => {
        try {
           const response = await validateScoreCard(userID, gameID, verifiedUserID,action);
           setIsReject(response.showRejectionForm);
           setMessage(response.message);
           setIconType(response.iconType);
        } catch (err) {
            setIconType('reject');
            setMessage('Error : Something went wrong, Try agin later')
        }
    }

    // http://localhost:3000/game-verification?id=53&gameId=75&action=verify
    const validationAttachments = () => {
        $('#rejection-form').bootstrapValidator({
            fields: {
                reason: {
                    validators: {
                        notEmpty: {
                            message: 'Please enter reason'
                        }
                    }
                },
            }
        });
    }

    const rejectionHandler = async (e) => {
        e.preventDefault();

        $('#rejection-form').data('bootstrapValidator').validate();

        if ($('#rejection-form').data('bootstrapValidator').isValid()) {
            try {
                const response = await rejectScoreCard({userID, gameID, reason});
                setIsReject(response.showRejectionForm);
                setMessage(response.message);
                setIconType(response.iconType);
            } catch (err) {
                setIsReject(false)
                setIconType('reject');
                setMessage('Error : Something went wrong, Try agin later')
            }
        }
    }

    return(
        <>
            <div className='game-verification-container'>
                {/* <Helmet>
                    <meta name="viewport" content="" />
                </Helmet> */}

                {/* <form id="form1"> */}
                    <div className="container text-center">

                        <div className="row margin-b10">
                            <div className="col-md-6 col-md-offset-3">
                                <div className="login-panel ">
                                    <a href="/login">
                                        <img className="img-responsive img-login-page margin-b0" src={bannerLogo} alt="" />
                                    </a>

                                    <h5 style={{ fontWeight: '600', marginTop: '10px', fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif", color: 'black'}}> GAEKWAD BARODA GOLF CLUB </h5>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3 jumbotron jumbotron-custom padding-lr30" >

                                {!isReject && (
                                    <>
                                        <div className="row" style={{marginTop: "2%"}}>
                                            <div className="col-sm-12">
                                                <span>
                                                    <p>
                                                        {/* <i className='fa fa-check-circle-o green bigger-300' style={{ marginTop: '20px', fontSize: '300px' }}></i>  */}
                                                        {iconType == 'verified' && (
                                                            <i className='fa fa-check-circle-o' style={{ marginTop: '20px', fontSize: '60px', color: '#69aa46' }}></i> 
                                                        )} 
                                                        {iconType == 'reject' && (
                                                            <i className='fa fa-times-circle-o' style={{ marginTop: '20px', fontSize: '60px', color: '#dd5a43' }}></i> 
                                                        )}
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='row margin-b10'>
                                            <div className='col-sm-12'>
                                                <span style={{ fontSize: '14pt', fontWeight: 'bold', fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif", color: 'black' }}>{message}</span>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {isReject && (
                                    <div className="row">
                                        <div className='col-sm-12'>
                                            <span style={{ fontSize: '14pt', fontWeight: 'bold', fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif", color: 'black' }}>{message}</span>
                                            <div id="rejection-form" className="kt-form margin-t20" onSubmit={(e) => e.preventDefault()}>
                                                <div className="form-group margin-t25">
                                                    <textarea
                                                        rows="5"
                                                        placeholder="Enter reason"
                                                        className="form-control"
                                                        value={reason}
                                                        onChange={(e) => setReason(e.target.value)}
                                                        id="reason"
                                                        name="reason"
                                                        type="text"
                                                    ></textarea>
                                                </div>
                                                <button
                                                    id="btnSave"
                                                    onClick={rejectionHandler}
                                                    type="button"
                                                    className={`btn btn-danger margin-0`}>
                                                    Reject
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                {/* </form> */}
            </div>
        </>
    )
}

export default GameVerification;