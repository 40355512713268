import { useEffect, useState } from "react";
import FormFooter from "./formFooter";
import ImageCropper from "../global/imageCropper";
import bannerLogo from '../../assets/images/GBGC_Login_logo.png';
import defaultlogo from '../../assets/images/defaultlogo.jpg';
import * as helper from "../global/helper"; 

const MemberForm = (props) => {

    const aadharPattern = /^[0-9]{12}$/;
    const mobilePattern = /^[0-9]{10}$/;

    const {memberDetails, updateMemberDetails, memberPhotoBlob, setMemberPhotoBlob, memberType, pageCount} = props;

    const [cropperData,                 setCropperData]             = useState(null);
    const [genderOption,                setGenderOption]            = useState([{ value:  'Male', label: 'Male'}, { value:  'Female', label: 'Female'},]);
    const [bloodGroupOption,            setBloodGroupOption]        = useState([{ value:  'A+', label: 'A+'}, { value:  'O+', label: 'O+'},{ value:  'B+', label: 'B+'}, { value:  'AB+', label: 'AB+'}, { value:  'A-', label: 'A-'}, { value:  'O-', label: 'O-'}, { value:  'B-', label: 'B-'}, { value:  'AB-', label: 'AB-'}]);
           
    const openCropper = async(e) => {
        e.preventDefault()
        setCropperData({
            imageRatio  : { width : 130, height : 140},
            dataHandler : (val) =>  setMemberPhotoBlob(val),
            header : 'Upload Profile Photo'
        })
    };
        
    const removePhotoHandler = async(e) => {
		e.preventDefault();
		setMemberPhotoBlob(null)
        updateMemberDetails('ProfilePhoto',  '');
	};

    return(
        <>
            <div className="panel panel-paper">
                <div className="panel-body form-onboarding" >
                    <div className="panel-heading text-center" style={{marginBottom: '20px'}}>
                        {/* Logo and club name */}
                        <img className="img-responsive img-login margin-b10" src={bannerLogo} alt="" style={{height: '80px'}}/>
                        <h3 className="panel-title mb-0 padding-t0"><strong>THE GAEKWAD BARODA GOLF CLUB, MEMBERSHIP AGREEMENT</strong></h3>
                    </div>
                    <fieldset className="spouse">
                        <legend>{memberType}</legend>
                        <div className="row">
                        <div className="col-lg-9">
                                <div className="form-group">
                                    <label >First Name <span className="invalid-feedback">*</span></label>
                                    <input type="text" className="form-control" id="inputFirstName"value={memberDetails.FirstName || ''} onChange={(event) => updateMemberDetails('FirstName',  helper.camelCase(event.target.value))}/>
                                </div>
                                <div className="form-group">
                                    <label >Middle Name</label>
                                    <input type="text" className="form-control" id="inputMiddleName"value={memberDetails.MiddleName || ''} onChange={(event) => updateMemberDetails('MiddleName',  helper.camelCase(event.target.value))}/>
                                </div>
                                <div className="form-group">
                                    <label>Last Name <span className="invalid-feedback">*</span></label>
                                    <input type="text" className="form-control" id="inputLastName"value={memberDetails.LastName || ''} onChange={(event) => updateMemberDetails('LastName',  helper.camelCase(event.target.value))}/>
                                </div>
                            </div>
                            {/* Logo on the right side */}
                            <div className="col-lg-3">
                                <div className="text-center" style={{ margin: '73px 42px auto' }}>
                                    {/* Image uploader */}
                                    <div className="kt-avatar kt-avatar--outline kt-avatar--changed offset-4" id="kt_user_add_avatar">
                                        {/* Edit button */}
                                        <div className="edit-icon" onClick={openCropper}>
                                            <div className="pencil-container">
                                                <i className="fa fa-pencil"></i>
                                                <span className="add-text">{!!memberPhotoBlob || memberDetails.ProfilePhoto ? "Update" : "Add"}</span>
                                            </div>
                                        </div>
                                        <div className="avatar__holder" style={{ backgroundImage: `url(${memberPhotoBlob ? URL.createObjectURL(memberPhotoBlob) : memberDetails.ProfilePhoto ? memberDetails.ProfilePhoto : defaultlogo})`, width: "130px", height: "140px", backgroundSize: "cover", backgroundPosition: "center"}}>
                                        </div>
                                        {(!!memberPhotoBlob || memberDetails.ProfilePhoto) && (
                                            <span className="avatar__cancel" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removePhotoHandler}>
                                                <div className="pencil-container">
                                                    <i className="fa fa-times"></i>
                                                    <span className="add-text">Delete</span>
                                                </div>
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label >Date of Birth <span className="invalid-feedback">*</span></label>
                            <input type="date" className="form-control" id="inputDOB" name="DOB" value={memberDetails.DOB ? moment(memberDetails.DOB).format('YYYY-MM-DD') : ''} onChange={(event) => updateMemberDetails('DOB', event.target.value)}/>
                        </div>
                        <div className="form-group">
                            <label >Gender <span className="invalid-feedback">*</span></label>
                            <select
                                value={memberDetails.Gender || ''}
                                onChange={(event) => updateMemberDetails('Gender', event.target.value)}
                                style={{width: "100%", height: "30px"}}
                                className="form-control form-control2" 
                                id="select"
                            >   
                                <option defaultValue={'Select Option'}>Select Gender</option>
                                {genderOption.map((type, index) => (
                                    <option key={index} value={type.value}>{type.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label >Email <span className="invalid-feedback">*</span></label>
                            <input type="email" className="form-control" id="inputEmail" name="Email" value={memberDetails.Email || ''} onChange={(event) => updateMemberDetails('Email', event.target.value)}/>
                        </div>
                        <div className="form-group">
                            <label >Mobile {memberType == 'Spouse Details' && <span className="invalid-feedback">*</span>}</label>
                            <input type="text" maxLength="10" className="form-control" id="inputMobile" name="Mobile" value={memberDetails.Mobile || ''} onChange={(event) => updateMemberDetails('Mobile', event.target.value)}/>
                            {!mobilePattern.test(memberDetails.Mobile) && !!memberDetails.Mobile&& <div className="invalid-feedback" >Please enter a valid 10-digit mobile number.</div>} {/* Display error message */}    
                        </div>
                        <div className="form-group">
                            <label >Blood Group <span className="invalid-feedback">*</span></label>
                            <select
                                value={memberDetails.BloodGroup || ''}
                                onChange={(event) => updateMemberDetails('BloodGroup', event.target.value)}
                                style={{width: "100%", height: "30px"}}
                                className="form-control form-control2" 
                                id="select"
                            >   
                                <option defaultValue={'Select Option'}>Select Blood Group</option>
                                {bloodGroupOption.map((type, index) => (
                                    <option key={index} value={type.value}>{type.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label >Aadhar Card </label>
                            <input type="text" className="form-control" id="inputAadharCard" name="AadharCard" maxLength="12" value={memberDetails.AadharCardNo || ''} onChange={(event) => updateMemberDetails('AadharCardNo', event.target.value)}/>
                            {!aadharPattern.test(memberDetails.AadharCardNo) && !!memberDetails.AadharCardNo  && <div className="invalid-feedback" >Invalid Aadhar card. Please enter a valid Aadhar number.</div>} {/* Display error message */}    
    
                        </div>
                        {memberType !=  'Spouse Details' && (
                            <div className="form-group">
                                <label > School / College </label>
                                <input type="text" className="form-control" id="inputSchool" name="School" value={memberDetails.School || ''} onChange={(event) => updateMemberDetails('School', event.target.value)}/>

                            </div>
                        )}
                    </fieldset>
                </div>
                <FormFooter num={pageCount}/>
             </div>
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
        </>
    )
};

export default MemberForm;