import axios from 'axios';
import { sleep, displayErrorSwal } from './common';
import { version, name } from '../../version.json'
import { setAuthState, setRefreshedCookie } from '../../assets/scripts/login-util';


const tokenErrors = ['bearer undefined', "bearer false"];
const errorLogs = ['Session Expired', 'Authentication failed', 'User not found', 'User not authorized', 'Authetication Error',
                    'User not authenticated'
                  ];

const getErrorMessage = (err) => {
    if(err.response && err.response.data){ 
      let errMsg =  err.response.data.message;
      if(errorLogs.includes(errMsg)){
        displayErrorSwal(errMsg);
        window.open('/', '_self');
        setAuthState();
      }
      return errMsg;
    }else{
      return err;
    }
  }

  export const axiosGet = async (url, config = { headers:''}) => {
    if(config && tokenErrors.includes(config.headers.auth_token)) return sleep(2000);
    try {
      if (config)
        config.headers = {...config.headers, fe_version : version, fe_name : name}
        const response = await axios.get(url, config);

        if (response.data.Message != 'success') {        
            if (response.data.Message == 'fail') {
                throw new Error(response.data.LongMessage || 'Unknown error')
            }else{
                throw new Error('Unknown error');
            }         
        }
  
      if(response.headers['refresh_fe'] === 'true'){
        window.location.reload();
      }
  
      if(response.headers['refresh_cookie'] === 'true' && config){
          await setRefreshedCookie(config);
      }
  
      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      throw new Error(message || err.message || 'Unknown error');
    }
  };


  export const axiosPost = async (url, data, config = { headers:''}) => {
    if(config && tokenErrors.includes(config.headers.auth_token)) return sleep(2000);
    try {
      if (config)
        config.headers = {...config.headers, fe_version : version, fe_name : name }
        const response  = await axios.post(url, data, config);
        if (response.data.Message != 'success') {
            if (response.data.Message == 'fail') {
                throw new Error(response.data.LongMessage || 'Unknown error');
            }else{
                throw new Error('Unknown error');
            }         
        }
  
      if(response.headers['refresh_fe'] === 'true'){
        window.location.reload();
      }
  
      if(response.headers['refresh_cookie'] === 'true' && config){
          await setRefreshedCookie(config);
      }
  
      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      throw new Error(message || err.message || 'Unknown error');
    }
  };