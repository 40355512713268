import { useEffect, useState } from "react";
import swal from 'sweetalert2';
import { postUsersGuest } from "../api/teeTimeAPI";

const AddGuest =(props)=> {
    const { selectedGuest, refetchUserGuest, onSelectGuest } = props;
    
    const [guest, setGuest] = useState( (typeof selectedGuest === "object") ? selectedGuest : {});

    useEffect(() => {    
        $('#modalAddGuest').modal({ backdrop: 'static', keyboard: false });
        $('#modalGuest').addClass('modal-blur'); 
        $('#modalAddGuest').on('hidden.bs.modal', function () {           
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        });
           
        validationAttachments();
    }, [])

    const validationAttachments =()=> {
        $('#addGuestForm').bootstrapValidator({
            fields: {
                txtName:{
                    validators: { notEmpty: { message: "Please enter guest name" }}
                },
                txtMobile: {
                    validators: {
                        notEmpty: { message: 'Please enter mobile no'},
                        numeric: {  message: 'Invalid mobile'},
                        stringLength: { min:10, max:10, message: 'Invalid mobile' },                      
                    }
                },               
            }
        });
    }
    
    const postGuest = async(e)=> {
        e.preventDefault();
        
        $('#addGuestForm').data('bootstrapValidator').validate();
        if(!$('#addGuestForm').data('bootstrapValidator').isValid()) return;
    
        $("#btnAddGuest").attr("disabled", true);
        try {         
            const { Guest } = await postUsersGuest(guest);
            
            if(guest.ID) 
                refetchUserGuest();
            else
                onSelectGuest(Guest)
            
            $("#btnAddGuest").attr("disabled", false);
            $('#modalAddGuest').modal('hide');
                        
            swal.fire({
                icon: "success", toast: true, timer:2500, position: "bottom-right",
                text: "guest added successfully", showConfirmButton: false
            });

            onClose();
        } catch (err) {
            $("#btnAddGuest").attr("disabled", false);
            swal.fire({icon: "error", titleText: "Error!", text: err.message})
            return;  
        }
    }
    
    const dataHandler =(key, value)=> setGuest(d =>({...d, [key]: value }));

    const onClose =()=> $("#modalGuest").removeClass('modal-blur');

    return (<>
        <div id="modalAddGuest" className="modal fade modal-responsive" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header padding-b0">
                        <button type="button" className="close" data-dismiss="modal"> <span aria-hidden="true" onClick={onClose}> &times; </span> </button>
                        <h5 className="modal-title" id="member-modal-title"> Add Guest </h5>
                    </div>
                    <div className="modal-body overflow-x padding-t0">
                        
                        <div id="ModalGuestHolder" className="padding-t15 padding-lr10 ">   
                            
                            <div id="addGuestForm" role="form" >                            
                                <div className="form-group">
                                    <input type="text" id="txtName" name="txtName" className="form-control" placeholder="Name"
                                        value={guest.Name || ''}
                                        onChange={({target}) => dataHandler("Name", target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <input type="number" id="txtMobile" name="txtMobile" className="form-control"  placeholder="Mobile"
                                        value={guest.Mobile || ''} 
                                        onChange={({target}) => dataHandler("Mobile", target.value)}                                
                                    />
                                </div> 
                                <div className="form-group">
                                    <input type="text" id="txtEmail" name="txtEmail" className="form-control"  placeholder="Email"
                                        value={guest.Email || ''}
                                        onChange={({target}) => dataHandler("Email", target.value)}                                                                
                                    />
                                </div>                                                                                                     
                                <div className="form-group">
                                    <input type="text" id="txtHandicap" name="txtHandicap" className="form-control"  placeholder="Handicap"
                                        value={guest.Handicap || ''}
                                        onChange={({target}) => dataHandler("Handicap", target.value)}                                                                
                                    />
                                </div>                                                                                                     
                            </div>                                                                                                     

                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default margin-r10" data-dismiss="modal" onClick={onClose}> Close </button>
                        <button type="button" id="btnAddGuest" className="btn btn-success" onClick={postGuest} > {!guest.ID ? 'Add': 'Update'} Guest </button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default AddGuest;