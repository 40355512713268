import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet } from "./axiosFunctions";

const validateScoreCardUrl                      = (userID, gameID, verifiedUserID, action) => `/api/game/validateScoreCard/${userID}/${gameID}/${verifiedUserID}/${action}`;
const rejectScoreCardUrl                        = '/api/game/rejectGame';

export const validateScoreCard = async (userID, gameID, verifiedUserID, action) => {
    // const token = getAuthState('token');
    // if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        validateScoreCardUrl(userID, gameID, verifiedUserID, action),
        { headers: '' }
    );
    return response;
};

export const rejectScoreCard = async (rejectionDetails) => {
    // const token = getAuthState('token');
    // if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( rejectScoreCardUrl, { rejectionDetails }, { headers: '' } );
    return response;
}