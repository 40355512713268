import React, { useState, useEffect } from 'react';
import bannerLogo from '../../assets/images/GBGC_Login_logo.png';
import poweredBy from '../../assets/images/GolfEZ-powered_PNG.png';
import { NavLink, useHistory } from 'react-router-dom';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { deviceDetect } from 'react-device-detect';
import { login } from '../api/loginAPI';
import { setAuthState } from '../../assets/scripts/login-util';
import { checkVersion } from '../../assets/scripts/versionComparer';

const Login = (props) => {

    const [loginData,           setLoginData]           = useState({ remember: false });
    const history 				                        = useHistory();

    useEffect(() => {
        validationAttachments();
    }, []);

    const loginDataHandler = (key, value)=>{
        setLoginData((d)=> { return {...d, [key] : value} });
    };

    const validationAttachments = () => {
        $('#ControlHolder').bootstrapValidator({
            fields: {
                txtUserMobile: {
                    validators: {
                        notEmpty: {
                            message: 'Please enter mobile no.'
                        },
                        numeric: {
                            message: 'Number is required'
                        }
                    }
                },
                txtPassword: {
                    validators: {
                        notEmpty: {
                            message: 'Please enter password'
                        }
                    }
                },
            }
        });
    }

    const loginHandler = async (e) => {
        e.preventDefault();
        
        $('#ControlHolder').data('bootstrapValidator').validate();
        if ($('#ControlHolder').data('bootstrapValidator').isValid()) {

            try {
                swal.fire({
                    titleText           : 'Loading',
                    text                : 'Please Wait...',
                    showConfirmButton   : false,
                    allowOutsideClick   : false,
                    onOpen: () => {
                        swal.showLoading();
                    }
                });
                const deviceData = deviceDetect();
                const device = await helper.getDeviceDetails(deviceData)
                const response = await login(loginData.userName, loginData.password, '', device,  loginData.remember);

                swal.close();

                if (response.Status && response.Status == 'unverified') {
                    $.session.set('Code', response.Session);
                    $.session.set('Mobile', response.User.Mobile);
                    $.session.set('UserID', response.User.ID);
                    history.push('/verify-otp');
                } else {
                    setAuthState({
                        token           : response.Token,
                        user            : {id: response.User.ID, userName: response.User.FirstName + ' ' + response.User.LastName},
                        FE_VERSION      : response.FE_VERSION,
                        serverTimestamp : response.serverTimestamp,
                        expiresOn       : response.expiresOn
                     }, true);
                     
                     window.cookieChangeHandler();
                     checkVersion(response.FE_VERSION);
                     props.onLogin();
                }

            } catch (err) {
                swal.close();
                swal.fire({
                    icon              : "error",
                    titleText         : "Error!",
                    text              : err.message
                  })
                return;  
            }
            
        }
    };

    return (
        <div className="container margin-t50">
            <div className="row">
                <div className="col-md-4 col-md-offset-4">
                    <div className="login-panel">
                        <NavLink to={'/login'}>
                            <img className="img-responsive img-login" src={bannerLogo} alt="" />
                        </NavLink>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h3 className="panel-title">Login</h3>
                        </div>
                        <div className="panel-body">
                            <div id="loginForm" role="form">
                                <div id="ControlHolder">
                                    <div className="form-group">
                                        <input 
                                            onChange={({ target }) => loginDataHandler('userName', target.value)}
                                            value={ loginData.userName || '' }
                                            id="txtUserMobile" 
                                            name="txtUserMobile" 
                                            className="form-control" 
                                            type="text" 
                                            placeholder="Mobile Number" 
                                            autoFocus="autofocus" 
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input 
                                            onChange={({ target }) => loginDataHandler('password', target.value)}
                                            value={ loginData.password || '' }
                                            id="txtPassword" 
                                            name="txtPassword" 
                                            className="form-control" 
                                            type="password" 
                                            placeholder="Password" 
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="inline">
                                            <input 
                                                type="checkbox" 
                                                className="ace" 
                                                id="chkRemember" 
                                                name="chkRemember" 
                                                defaultChecked={ loginData.remember ? "checked" : "" }
                                                onChange={() => loginDataHandler("remember", !loginData.remember)}
                                            />
                                            <span className="lbl">Remember Me</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="clearfix form-actions">
                                    <button 
                                        type="button" 
                                        id="btnLogin" 
                                        name="btnLogin" 
                                        onClick={loginHandler}
                                        className="btn btn-lg btn-success btn-block">Login
                                    </button><br />
                                    <NavLink to={'/forgot-password'} className="lbl text-center">Forgot Password?</NavLink><br />
                                    <NavLink to={'/registration'} className="lbl text-center">No account yet? Create one</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="container-power-login">                        
                        <a className="power" href='https://golfezapp.com/' target="_blank">
                             <img src={poweredBy} alt=""/>
                        </a>
                    </span>
                </div>
            </div>
        </div>
    )

}

export default Login;