import { useContext } from "react";
import { signatureContext } from "../../context/context";
import './membershipForm.css';

const FormFooter = (props) => {

    const signatureImg = useContext(signatureContext)
    return(
        <>
            <footer className="simple-footer">
                <hr style={{borderTop: '1px solid #c7bfbf'}}/>
                <div className="footer-content">
                    {(signatureImg) ? 
                        <div className="signature-text"style={{ width: "130px", height: "35px", border: '1px solid #ddd',borderRadius: '4px',display: 'flex',alignItems: 'center',justifyContent: 'center',textAlign: 'center',overflow: 'hidden',fontSize: '1rem',marginLeft: '19px'}}>
                        <strong>{signatureImg}</strong>
                    </div>                    
                    : <div style={{ width: "130px", height: "30px"}}></div>}
                    <h6 className="margin-20 padding-b20 margin-t10">Signature of Applicant</h6>
                    <span className="page-number">Page: {props.num}</span>
                </div>
            </footer>
        </>
    )
};

export default FormFooter;